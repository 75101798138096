<template>
  <div>
    <el-form label-width="150px" size="small">
      <h4>企业到期推送
      </h4>
      <el-form-item label="推送方式:">
        <el-checkbox-group v-model="form.typeList">
          <el-checkbox :label="1">短信</el-checkbox>
          <el-checkbox :label="2">邮件</el-checkbox>
          <el-checkbox :label="4">平台</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="推送时间:">
        <el-select v-model="form.pushDuration">
          <el-option :value="3" label="到期前3天"></el-option>
          <el-option :value="5" label="到期前5天"></el-option>
          <el-option :value="7" label="到期前7天"></el-option>
          <el-option :value="15" label="到期前15天"></el-option>
          <el-option :value="30" label="到期前30天"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推送范围:">
        <el-select v-model="form.scope" > 
          <el-option :value="0" label="全部客户"></el-option>
          <el-option :value="1" label="自定义客户"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组织类型:"  >
        <el-select v-model="form.organizationType" @change="getCompanyArr();form.tenantCodeScopeList=[]">
          <el-option value="" label="全部"></el-option>
          <el-option :value="1" label="企业组织"></el-option>
          <el-option :value="0" label="社区组织"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户类型:"  >
        <el-checkbox-group v-model="form.userTypeList" @change="getCompanyArr();form.tenantCodeScopeList=[]">
          <el-checkbox :label="1">付费</el-checkbox>
          <el-checkbox :label="2">试用</el-checkbox>
          <el-checkbox :label="4">第三方渠道</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="所属公司" v-if="form.scope===1">
        <el-select v-model="form.tenantCodeScopeList" multiple filterable placeholder="请选择" clearable style="width: 400px;">
          <el-option v-for="(item,i) in companyArr" :key="i" :label="item.companyName" :value="item.tenantCode"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="推送内容:">
        <el-input :rows="5" v-model="form.pushContent" type="textarea" style="width:400px" />
      </el-form-item>
    </el-form>
    <div class="btndiv">
      <el-button size="small" type="primary" v-if="isAuth('platform-config:messagepush:update')" :loading="loading" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        organizationType: 0, //组织类型 -1 全部；0 社区；1 企业
        pushContent: '贵公司使用的点赞积分系统${days}天后（${year}年${month}月${day}日）即将到期，请联系您的专属客服或致电400-880-5209进行处理',
        scope: 0, //0全部客户 1自定义客户
        userTypeList: [], //0付费 1试用 4第三方渠道
        typeList: [],  //1 短信 2邮件 4平台（APP，企业端）
        pushDuration: '',
        tenantCodeScopeList:[]
      },
      companyArr: []
    };
  },
  created() {
    this.getCompanyArr();
    this.getDetail();
  },
  
  methods: {
    // 获取公司列表
    getCompanyArr() {
      let param = {
        organizationType: this.form.organizationType,
        userTypeList: this.form.userTypeList,
      }
      this.$post('/platform-admin/tenant/listByParameter', param).then(res => {
        if (res && res.code == 0) {
          this.companyArr = res.data
        }
      })
    },
    getDetail() {
      this.$get('/platform-config/expiredPush').then(res => {
        if (res && res.code === 0) {
          
          if(res.data)this.form = res.data;
          console.log(this.form.scope)
          this.getCompanyArr();
        }
      })
    },
    submit() {
      this.loading = true;
      this.$post('/platform-config/expiredPush', this.form).then(res => {
        if (res && res.code === 0) {
          this.form = res.data;
          this.$message.success('保存成功！');
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style scoped>
h4 {
  font-size: 16px;
  color: #409eff;
  margin: 20px;
}

.checkbox {
  width: 250px;
  margin-left: 30px;
}

.btndiv {
  margin-top: 50px;
  margin-left: 150px;
}
</style>
